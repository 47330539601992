<script lang="ts" setup></script>

<template>
  <div>
    <div class="h-screen background">
      <UMain class="gradient">
        <slot />
      </UMain>
    </div>
  </div>
</template>


<style scoped>
.gradient {
  background: linear-gradient(180deg, rgb(var(--color-primary-500) / 0) 0, #FFF 100%);
}
</style>